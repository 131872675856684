@import "src/utils/styles";
@import "src/utils/mixin";

.box {
  width: 100%;
  position: relative;
}

.wrapper {
  display: flex;
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 10px;

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.label {
}

.input {
  border: none;
  padding: $padding10;
  background-color: transparent;
  flex-grow: 1;

  font-size: 16px;
  color: inherit;
  @include text('regular');

  outline: none;

  &::placeholder {
    color: $text-placeholder;
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    @include text('regular');
  }

  &:disabled {
    background-color: #e8e8e8 !important;
  }
}

.errorMessage {
  color: var(--main-color);
  font-size: 14px;
  @include text('regular');

  position: absolute;
  bottom: -18px;
  left: 4px;
}

