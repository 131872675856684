@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: $padding20 0;
}

.button {
  border: 1px solid var(--main-color);
  border-radius: 8px;
  background-color: #ffffff;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 4px;

  width: 40px;
  height: 40px;

  color: var(--text-color);
  font-size: 14px;
  @include text('regular');

  &Active {
    background-color: var(--main-color);
    color: #ffffff;
    @include text('semibold');
  }
}

.pdfControls {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  height: 220px;
  z-index: 1000;

  &Button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.22);
    border: none;
    border-radius: 12px;
    outline: none;

    & span {
      font-size: 24px;
      color: var(--text-color);
    }
  }
}

.transformWrap {
  height: calc(var(--app-height) - 220px);
  overflow-y: scroll;
}

.doc {
  height: calc(var(--app-height) - 220px);
  width: 100%;
  //z-index: 1000;
  //display: flex;
  //align-items: center;
}
