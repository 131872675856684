@import "src/utils/styles";
@import "src/utils/mixin";

.avatarWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid var(--main-color);
  background-color: #f0edfd;

  position: relative;


  &Image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  & img {
    width: 100%;
    object-fit: cover;
  }

  &Button {
    width: 50px;
    height: 50px;

    position: absolute;
    right: 0;
    bottom: 0;

    background-color: transparent;
    border: none;
    outline: none;
  }
}

.title {
  color: #FFFFFF;
  font-size: 16px;
  @include text('regular');

  text-align: center;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;

  &Col {
    width: 48%;
    margin-bottom: 10px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &Item {
    width: 100%;
  }
}
