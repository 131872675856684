@import "src/utils/styles";
@import "src/utils/mixin";

.map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &Header {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &Footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 100;
  }
}

.controls {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 100;
}

.button {
  position: fixed;
  right: 20px;
  min-width: 50px;
  max-width: 130px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-color);
  margin-bottom: 10px;
  z-index: 1;
  padding: 0 5px;

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    display: inline-block;
    margin-left: 5px;
    flex-grow: 1;
  }
}

.cart {
  top: 80px;
  height: 55px;
  z-index: 2;

  @media (max-width: 395px) {
    height: 45px;
  }
}

.zoomIn {
  top: 50%;
}

.zoomOut {
  top: calc(50% + 60px);
}

.home {
  left: 20px;
  bottom: 10%;
}

.back {
  left: 160px;
  bottom: 10%;
  width: 100px;
}

.navigate {
  bottom: 10%;

  & svg {
    transform: rotate(45deg);
  }
}

.balloon {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;
  position: relative;
  width: 300px;

  &Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &Close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    outline: none;

  }

  &Strong {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
    margin: 0;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 16px;
    @include text('regular');
    margin: 0;
  }

  &Separator {
    padding: 5px 0;
  }

  &More {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
    margin-left: 20px;
    padding: 0 10px;
  }
}

.balloonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border: 2px solid var(--main-color);
  border-radius: 12px;
}

.addButton {
  position: fixed;
  bottom: 50px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;

  border-radius: 10px;
  background-color: #F0F2F8;
  border: 1px solid var(--border-color);


  &:active {
    border: 1px solid var(--main-color);
  }
}

.taskFormRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &LabelBox {
    width: 20%;
  }

  &InputBox {
    position: relative;
    width: 75%;
  }
}

.selectWrap {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select {
  display: flex;
  width: 100%;
  height: 41px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;

  &Disabled {
    background-color: #e8e8e8;
  }

  & span {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: var(--text-color) !important;
  }

  &Label {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular');
  }
}

.space {
  padding: 5px 0;
}

.list {
  padding: 0;
}
