@import "src/utils/styles";
@import "src/utils/mixin";

.table {
  display: flex;
  flex-direction: column;

  overflow-x: scroll;
  border: 1px solid #b2b2b2;
  border-radius: 4px;

  &Head {
    display: flex;
    border-bottom: 1px solid #b2b2b2;
    background-color: #FFFFFF;
    min-height: 40px;

    & .tableItem {
      padding: 0 10px;
    }
  }

  &RowCollapse {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    &:nth-child(even) {
      background-color: #d7d7d7;
    }

    &Grey {
      background-color: #d7d7d7;
    }

    &Additional {
      display: flex;
      background-color: #f7f8ff;

      .tableItem {
        padding-left: 20px;
      }
    }
  }


  &RowBody {
    display: flex;
    flex-direction: column;
    background-color: #e6fff6;
  }

  &RowInner {
    display: flex;
    width: 100%;

    &Padding {
      padding-left: 20px;
      border-bottom: 1px solid #e7e7e7;

      .tableItem {
        padding: 5px 0;
      }
    }
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;

    &Center {
      text-align: center;
    }

    &:nth-child(1) {
      width: 100%;
      justify-content: flex-start;
    }

    &:nth-child(even) {
      background-color: rgba(187, 224, 255, 0.53);
    }

    &:empty {
      background-color: #f7f8ff;
    }

    &Last {
      width: 50px;
      padding: 5px;
    }
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin: 0;
    max-width: 130px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Text {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');

    margin: 0;
  }
}

.checkbox {
  padding: 10px 13px;
  display: flex;
  align-items: center;
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid #888888;
  transform: rotate(180deg);

  &Upped {
    transform: rotate(0deg);
  }
}

.more {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    height: 33px;
    width: 200px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    &:disabled {
      opacity: 0.5;
    }
  }
}
