@import "src/utils/styles";
@import "src/utils/mixin";

.loading {
  color: var(--text-color);
  font-size: 12px;
  line-height: 12px;
  @include text('light');
  margin: 0;
  height: 10px;
}
