@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  padding: 20px;
}

.box {
  width: 100%;
  padding: 10px 20px;
  overflow-x: hidden;

  &Label {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular');
  }
}

.table {
  display: flex;
  flex-direction: column;

  overflow-x: scroll;
  border: 1px solid #b2b2b2;
  border-radius: 4px;

  &Head {
    display: flex;
    border-bottom: 1px solid #b2b2b2;
    background-color: #FFFFFF;
  }

  &Row {
    display: flex;
    background-color: #d7d7d7;

    &:nth-child(odd) {
      background-color: #f8fcff;
    }

  }

  &RowCollapse {
    display: flex;
    background-color: #ffffff;

    &Grey {
      background-color: #d7d7d7;
    }

    &Inner {
      display: flex;
      background-color: #efefef;
    }

    &Additional {
      display: flex;
      background-color: #f7f8ff;

      .tableItem {
        padding-left: 20px;
      }
    }
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    width: 80px;

    &:nth-child(1) {

      width: 100%;
      justify-content: flex-start;
    }

    &:nth-child(even) {
      background-color: rgba(187, 224, 255, 0.53);
    }

    &:empty {
      background-color: #f7f8ff;
    }

    &Last {
      width: 50px;
      padding: 5px;
    }
  }

  &CheckWrap {
    padding: 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin: 0;
    max-width: 130px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &Text {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');

    margin: 0;
  }
}

.arrowUp {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 8px solid #888888;
  transform: rotate(180deg);

  &Upped {
    transform: rotate(0deg);
  }
}

.tableTooltip {
  padding-bottom: 3px;
  display: flex;
  justify-content: flex-end;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    padding: 5px 10px;

    color: var(--text-color);
    font-size: 12px;
    @include text('regular');

    &:disabled {
      opacity: .5;
    }
  }
}

.chart {
  height: 250px;
}

.spiner {
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  padding: 10px 13px;
  display: flex;
  align-items: center;
}

.list {
  padding: 15px 20px 20px;

  &Item {
    margin-bottom: 10px;
  }

  &Name {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin-top: 0;
  }
}

.card {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;

  &Header {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    &Row {

      padding-left: 10px;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  &Avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e7e7e7;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 20px;
      height: auto;
      object-fit: cover;
    }
  }

  &DateWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &Date {
    color: var(--text-color);
    font-size: 14px;
    line-height: 18px;
    @include text('regular');
    display: inline-block;
    margin-left: 7px;
  }

  &Title {
    color: var(--text-color);
    font-size: 16px;
    line-height: 16px;
    @include text('regular');

    margin: 0;


    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 16px;
    @include text('regular');

    margin: 0;


    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &Name {
    background-color: #f5f5f5;
    padding: 2px 6px;
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
    border-radius: 4px;
    display: inline-flex;
    align-self: baseline;
    margin: 0 0 3px;

    &:nth-child(2) {
      margin-bottom: 10px;
    }
  }

  &TagList {
    display: inline-flex;
    flex-wrap: wrap;
    align-self: baseline;
    margin: 5px 0;

  }

  &Tag {
    color: #adadad;
    font-size: 12px;
    line-height: 13px;
    @include text('semibold');
    display: inline-block;
    margin-left: 5px;
  }

  &Label {
    color: var(--text-color);
    font-size: 12px;
    @include text('light');
    display: inline-block;
    margin-right: 10px;
  }

  &Body {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
  }

  &Comment {
    background-color: #e5fffb;
    padding: 3px 3px 3px 10px;
    border: 1px solid #adc5bb;
    border-radius: 8px;

    &Text {
      color: var(--text-color);
      font-size: 14px;
      line-height: 16px;
      @include text('regular');
      display: inline-block;
      margin: 0;
    }

    &List {
      padding: 0;
      margin: 0;
      list-style: none;
    }


    &ListItem {
      padding: 10px;
      width: 70%;
      margin: 0 auto 5px 0;
      background-color: #daf5ee;
      text-align: left;
      border-radius: 8px;
    }

    &ListItemTo {
      padding: 10px;
      width: 70%;
      margin: 0 0 5px auto;
      background-color: #ebfdf9;
      text-align: right;
      border: 1px solid #d6e7e2;
    }
  }

  &Footer {
    padding: 10px 5px 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &AnswerInput {
    width: 70%;
    height: 40px !important;
    resize: vertical;
    padding: 10px;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    &::placeholder {
      color: #c2c2c2;
      font-size: 14px;
      @include text('regular');
    }
  }

  &AnswerButton {
    width: 28%;
    height: 40px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    border: 1px solid var(--main-color);
    border-radius: 8px;
  }

  &Phone {
    padding: 0;

    & a {
      font-size: 14px;
      @include text('regular');
    }
  }
}


.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
  margin-top: 20px;

  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.inputWrap {
  border: 1px solid #DCE1EF;
  border-radius: $border-radius-12;
  background-color: #ffffff;
  overflow: hidden;
  margin: 10px 0;
}

.inputRange {
  display: flex;
  width: 100%;
  min-height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  &W70 {
    width: 70%;
    min-height: 30px;
    margin-right: 0;
  }

  & input {
    text-align: center;
  }
}

.dataPickerWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Tooltip {
    position: absolute;
    top: 5px;
    left: 30px;
    color: var(--main-color);
    font-size: 10px;
    @include text('ligth');
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 48%;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.collapse {
  background-color: #FFFFFF;
  padding: 0 10px;
  display: flex;


  &Button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px;
  }

  &Name {
    flex-grow: 1;
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');

    padding: 0 10px;
  }
}

.animIcon {
  display: inline-block;
  transform: rotate(0deg);

  &Open {
    transform: rotate(180deg);
  }
}

.answer {
  &Row {
    display: flex;
    justify-content: space-between;
  }

  &Name {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
  }

  &Time {
    color: var(--text-color);
    font-size: 10px;
    @include text('regular');
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}
