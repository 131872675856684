@import "src/utils/styles";
@import "src/utils/mixin";

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $padding20;
  position: relative;

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding10 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 40px;
    line-height: 40px;
    @include text('regular');

  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin-top: $padding10;
  }
}
