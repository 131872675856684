@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20 $padding20 100px;
}

.switcher {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;

  &Tooltip {
    color: var(--main-color);
    font-size: 12px;
    @include text('regular');

    position: absolute;
    top: -20px;
    left: 5px;
  }

  &Buttons {
    width: 75%;
  }

  &Filter {
    width: 22%;
  }
}

.containerPdf {
  margin-top: 20px;
}

.openFilter {
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  outline: none;
  padding: 5px 10px;
  flex-grow: 1;

  color: var(--text-color);
  font-size: 12px;
  @include text('regular');
}

.tabs {
  position: relative;
  min-height: 510px;
}

.loaderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.cards {
  display: flex;
  justify-content: space-between;
  padding: 0 0 $padding20 0;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  &Box {
    width: 49%;
  }

}

.card {
  width: 100%;
  height: 131px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;

  &Body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $padding10 $padding10 0;
  }

  &Chart {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin: 0;
  }
}

.data {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 25px;

  &Description {
    color: var(--text-color);
    font-size: 8px;
    line-height: 8px;
    @include text('leght');
    margin: 0;
  }

  &Revenue {
    color: var(--text-color);
    font-size: 16px;
    line-height: 16px;
    @include text('semibold')
  }

  &ChecksWrap {
    width: 60px;
    height: 20px;
    text-align: right;
  }

  &Checks {
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 10px;
    line-height: 10px;
    @include text('regular');

    & svg {
      font-size: 10px;
      display: inline-block;
      margin-right: 2px;
    }
  }
}

.inputWrap {
  border: 1px solid #DCE1EF;
  border-radius: $border-radius-12;
  background-color: #ffffff;
  overflow: hidden;
  margin: 10px 0;
}

.inputLabel {
  font-size: 10px;
  line-height: 10px;
  @include text('regular');
  display: inline-block;
}

.inputRange {
  display: flex;
  width: 100%;
  height: 33px;
  border: none;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.filter {
  margin-top: 20px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  background-color: #FFFFFF;

  &Row {
    padding: 3px 0;
  }
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 48%;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.period {
  padding: 10px;

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.filterControls {
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-top: 20px;
  padding: 20px;
}


.filterOpt {
  padding: 0 10px;

  &:nth-child(even) {
    background-color: #f0edfd;
  }

  &Name {
    color: var(--text-color);
    font-size: 16px;
    @include text('light');
    margin: 2px 0;
  }

  &Row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -5px;
    box-sizing: initial;
  }

  &Item {
    margin: 0 5px 10px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    border-radius: 8px;
    border: 1px solid var(--main-color);
    padding: 6px;
    background-color: #FFFFFF;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    width: 300px;
    height: 2px;
    background-color: #C32B46;
  }
}

.chartName {
  color: var(--text-color);
  font-size: 16px;
  @include text('semibold');
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;

  &Text {
    color: var(--text-color);
    font-size: 20px;
    @include text('light');
  }
}

.empty {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;

  & p {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular')
  }
}

.description {
  padding: 20px 0;
  position: absolute;
  bottom: 0;

  &Text {
    color: var(--text-color);
    font-size: 16px;
    @include text('light');
    margin: 0;
  }
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;

  &Disabled {
    background-color: #e8e8e8;
    border: 1px solid $text-placeholder;
    color: var(--text-color) !important;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}
