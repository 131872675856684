:root {
    --app-height: 100%;
    --border-color: #C32B46;
    --text-color: #0D101C;
    --text-light-color: #49494d;
    --text-placeholder: #6E7591;
    --main-color: #C32B46;
    --second-color: #f68700;
    --disable-color: #e3e3e3;
    --green-color: #00ff25;
    --blue-color: #0080ff;
    --pink-color: #ff00d3;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
}

.container {
    padding: 0 20px 100px;
}

/* Скрыть стрелочки управления в input type="number" */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Поддержка для Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ant-select-selector {
    width: 100% !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    color: #0D101C !important;
    padding: 0 !important;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #e8e8e8 !important;
}

.ant-picker-panels {
    flex-direction: column !important;
}


.ant-picker-presets {
    min-width: 100px !important;
    max-width: 150px !important;
}

/*.ant-picker-range {*/
/*    flex-direction: row-reverse!important;*/
/*}*/
.ant-picker-range.ant-picker-outlined:focus {
    border-color: var(--main-color);
}

.ant-picker-outlined.ant-picker-disabled {
    background-color: #e8e8e8 !important;
    border: 1px solid var(--main-color);
}

.ant-picker-outlined.ant-picker-disabled input {
    color: #0D101C !important;
}

.space-10 {
    display: block;
    padding: 5px;
}

.scanner-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

span {
    color: var(--text-color);
}

.rhap_container {
    box-shadow: 0 0 3px 0 var(--main-color) !important;
    border-radius: 8px;
}

.rhap_main-controls-button svg {
    color: var(--main-color) !important;
}


.rhap_progress-bar {
    background-color: var(--main-color) !important;
}

.rhap_time, .rhap_volume-indicator, .rhap_volume-bar {
    color: var(--main-color) !important;
}

.rhap_play-pause-button {
    color: var(--main-color) !important;
}

.rhap_volume-button {
    color: var(--main-color) !important;
}

@media (max-width: 576px) {
    .custom-range-picker-dropdown {
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;
    }
}

.ymaps-2-1-79-map-copyrights-promo {
    display: none;
}

.Placemark {
    transition: all 0.3s ease; /* Длительность анимации и тип анимации */
}

/* Стили для увеличенного Placemark */
.Placemark.large {
    transform: scale(1.5); /* Увеличение размера */
    transform-origin: center; /* Центрирование увеличения */
}

/* Стили для сдвинутого Placemark */
.Placemark.offset {
    transform: translate(-10px, -20px); /* Сдвиг иконки */
}

.error-container {

}
