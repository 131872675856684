@import "src/utils/styles";
@import "src/utils/mixin";

.page {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &Item {
    width: 48%;
    display: flex;
    align-items: center;
  }
}

.version {
  text-align: center;
  color: var(--text-color);
  font-size: 12px;
  @include text('light');
}

.simpleBox {
  height: 64px;
}

.selectWrap {
  padding: 20px 0;
}

.select {
  display: flex;
  width: 100%;
  height: 56px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;

  & span {
    font-size: 16px !important;
    font-weight: bold !important;
    color: var(--text-color) !important;
  }

  &Label {
    color: var(--text-color);
    font-size: 16px;
    @include text('light');
  }
}
