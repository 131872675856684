@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  padding: 20px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  width: 100%;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  background-color: #FFFFFF;
  overflow: hidden;

  &Header {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #7a7a7a;
    padding: 3px 0;

    &:empty {
      display: none;
    }

  }

  &Head {
    padding: 0 3px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

    @include tableColumn();

  }

  &HeadLessFive {
    width: 100%;
    padding: 0 3px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

  }

  &HeadText {
    font-size: 8px;
    text-align: center;

    &:empty {
      display: none;
    }
  }

  &HeadInner {
    display: flex;
    height: 100%;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;
    background-color: #f3f3f3;
    padding: 3px 0;
    border-radius: 2px;
  }

  &Row {
    display: flex;

    &:nth-child(2n) {
      background-color: #efefef;
    }

    &Text {
      font-size: 8px;
      max-width: 110px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:empty {
        display: none;
      }
    }
  }


  &RowItem {
    display: flex;
    align-items: center;
    padding: 3px;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

    @include tableColumn();

    &Num {
      justify-content: flex-end;
    }
  }

  &RowItemLessFive {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 3px;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }


    &Num {
      justify-content: flex-end;
    }
  }
}

.pdfControls {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
  height: 220px;
  z-index: 1000;

  &Button {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(112, 112, 112, 0.22);
    border: none;
    border-radius: 12px;
    outline: none;

    & span {
      font-size: 24px;
      color: var(--text-color);
    }
  }
}

.sort {
  display: flex;
  flex-direction: column;

  &Item {
    border: none;
    outline: none;

    display: flex;
    justify-content: flex-end;
    background-color: #f3f3f3;

    & span {
      width: 15px;
      height: 15px;
      padding: 0;
    }

    & svg {
      height: .5rem;
    }
  }
}

.empty {
  padding: 40px 20px 20px;
  text-align: center;

  &Title {
    color: var(--text-color);
    font-size: 24px;
    @include text('regular');
    margin: 0;
  }

  &Text {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular');
    margin: 0;
  }
}
