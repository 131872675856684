@import "src/utils/styles";
@import "src/utils/mixin";

.inputRange {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

  &Disabled {
    background-color: #f6f6f6;
    border: 1px solid $text-placeholder;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.dataPickerWrapper {
  position: relative;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Tooltip {
    position: absolute;
    top: 5px;
    left: 30px;
    color: var(--main-color);
    font-size: 10px;
    @include text('ligth');
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.filterWrapper {
  padding: 10px 0;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.card {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
  overflow: hidden;
  border: 1px solid var(--main-color);

  &Header {
    background-color: #e7e7e7;
    border-bottom: 1px solid var(--text-placeholder);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 5px;
  }

  &Subtext {
    @include text('light');
    font-size: 14px;
    color: var(--text-color);
  }

  &Title {
    @include text('regular');
    font-size: 16px;
    color: var(--text-color);
    text-transform: uppercase;
  }

  &Text {
    @include text('regular');
    font-size: 14px;
    color: var(--text-color);
  }

  &Row {
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }

  &Col2 {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #f3f3f3;
  }
}
