@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: 20px;
}

.switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &Button {
    width: 24%;

    background-color: #ffffff;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    @include text('regular');
    font-size: 12px;
    color: var(--text-color);
    padding: 7px;
  }

  &Active {
    background-color: var(--main-color);
    color: #FFFFFF;
  }
}

.tab {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 12px;
  width: 100%;
  margin-top: 20px;

  &Card {
    display: flex;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  &ImageBox {
    width: 35%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Box {
    width: 65%;
    padding: 0 5px;
    margin-bottom: 10px;

    &:first-of-type {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      margin-bottom: 0;
    }
  }

  &Title {
    margin: 0 0 5px;
    @include text('regular');
    font-size: 18px;
    color: var(--text-color);
  }

  &Subtext {
    @include text('light');
    font-size: 16px;
    color: var(--text-color);
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
    flex-grow: 1;
    width: 100px;
  }

  &Text {
    @include text('regular');
    font-size: 16px;
    color: var(--text-color);
    margin: 0 0 5px;

    display: flex;
    align-items: flex-start;
    min-height: 17px;
    text-align: right;

    &:nth-child(3),
    &:nth-child(10) {
      margin-top: 10px;
    }
  }

  &TextBold {
    @include text('semibold');
    font-size: 16px;
    color: var(--text-color);
    margin: 0 0 5px;

    display: flex;
    align-items: flex-start;
    min-height: 17px;
    text-align: right;

    &:nth-child(3) {
      margin-top: 10px;
    }
  }

  &Separator {
    width: 100%;
    height: 1pt;
    background-color: #d5d5d5;
    display: block;
  }
}


.button {
  position: absolute;
  right: 0;
  min-width: 50px;
  max-width: 130px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-color);
  margin-bottom: 10px;
  z-index: 1;
  padding: 0 5px;

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    display: inline-block;
    margin-left: 5px;
    flex-grow: 1;
  }
}


.zoomIn {
  top: 50%;
}

.zoomOut {
  top: calc(50% + 60px);
}

.rating {
  font-size: 12px;
}

.name {
  margin: 0;
  color: var(--text-color);
  font-size: 12px;
  @include text('semibold');
}

.tags {
  display: flex;
  flex-direction: column;

  &Item {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    display: inline-block;
  }
}

.map {
  height: 400px;
  position: relative;


}
