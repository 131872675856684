@import "src/utils/styles";
@import "src/utils/mixin";

.table {
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  background-color: #FFFFFF;
  overflow: hidden;

  &Head {
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    & .tableItem {
      height: 69px;

    }
  }

  &Row {
    width: 100%;
    display: flex;
    padding: 0 3px;

    &:nth-child(2n) {
      background-color: #efefef;
    }

    &Text {
      font-size: 12px;
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:empty {
        display: none;
      }
    }
  }

  &Item {
    border-right: 1px solid #c2c2c2;
    padding: 0 3px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:last-of-type {
      border-right: none;
    }

    &:nth-child(2),
    &:nth-child(3) {
      & p {
        padding-left: 5px;
      }
    }
  }

  &Sort {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &Title {
    font-size: 12px;
  }


  // ===========


  &RowItem {
    display: flex;
    align-items: center;
    padding: 3px;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

    width: 90px;

    &:nth-child(1) {
      width: 30px;
    }

    &:nth-child(2) {
      width: 110px;
    }

    &Num {
      justify-content: flex-end;
    }
  }
}
