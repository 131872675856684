@import "src/utils/styles";
@import "src/utils/mixin";

.buttons {
  display: flex;
  width: 100%;
  padding: 20px;

  &Item {
    flex-grow: 1;
    font-size: 14px;
    color: var(--text-color);
    @include text('regular');
    background-color: #ffffff;
    border: 1px solid var(--main-color);
    padding: 5px;

    &Active {
      background-color: var(--main-color);
      color: #ffffff;
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.img {
  width: 100%;
  height: 200px;

  & img {
    width: 100%;
    object-fit: cover;
  }
}
