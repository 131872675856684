@import "src/utils/styles";
@import "src/utils/mixin";

.state {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  &Button {
    width: 19%;
    height: 56px;
    padding: 5px;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    outline: none;
    border-radius: 12px;
    color: var(--text-color);
    font-size: 12px;
    @include text('light');

    &:active {
      opacity: 0.7;
    }

    &Active {
      background-color: #C32B46;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }
  }
}

