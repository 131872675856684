@import "src/utils/styles";
@import "src/utils/mixin";

.qCard {
  min-height: 84px;
  margin-bottom: 20px;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #ffffff;

  padding: 0;

  color: var(--text-color);
  font-size: 16px;
  @include text('regular');

  &Active {
    background-color: var(--main-color);
    color: #ffffff;
  }

  @media (min-width: 768px) {
    width: calc(100% / 3 - 10px);
  }
}
