@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20;
}

.count {
  color: var(--text-color);
  font-size: 16px;
  @include text('regular');
  margin: 0 0 10px 0;

  width: 100%;
}

.chapter {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');
  margin: 0 0 10px 0;
}

.wrapperText {
  padding: 6px 12px;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #ffffff;
  min-height: 135px;
}

.text {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');

  margin: 0;
}

.buttons {
  padding: 0 $padding20;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: $padding10;

  &Box {
    width: 48%;
  }
}

.variableList {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: $padding20 0;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &Box {
    width: 49%;
  }
}

.modalText {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');
  margin: 0;
  text-align: center;
}

.modalButtons {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  &Box {
    width: 48%;
    height: 44px;
  }

  &BoxFull {
    width: 100%;
    height: 44px;
  }
}

.label {
  color: #ffffff;
  font-size: 16px;
  @include text('regular');
  margin-bottom: 5px;
}

.remTextarea {
  width: 100%;
  margin-top: $padding20;
}

.files {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: $padding10 0;
}

.fileWrap {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
}

.wrapReaderButton {
  text-align: center;
}

.minText {
  display: block;
  width: 59px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 10px;
  @include textMulish('regular');
}

.fileReaderButton {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;

  &Item {
    display: inline-flex;
    align-self: flex-start;
    background-color: #ffffff;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    padding: 5px $padding10;
    margin-bottom: 8px;

    @include text('regular');
    color: var(--text-color);
    font-size: 14px;
  }
}

.selectActionModal {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.space {
  padding: 4px 0;
}

.loaderWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: rgba(255, 255, 255, 0.63);

  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderText {
  color: var(--text-color);
  font-size: 14px;
  text-align: center;
  @include text('semibold');
}

.emptyBlock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &Text {
    @include text('regular');
    color: #fff;
    font-size: 16px;
  }
}
