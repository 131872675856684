@import "src/utils/styles";

.screenshot {
  width: 100%;
  position: fixed;
  bottom: 20px;
}

.camera {
  height: calc(100vh - 100px);
}

.viewContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.image {
  object-fit: cover;
}

.screenshotButton {
  height: 57px;
  position: fixed;
  bottom: 80px;
  left: 20px;
  right: 20px;
}
