@import "src/utils/styles";
@import "src/utils/mixin";

.box {
  position: relative;
}

.wrapper {
  display: flex;
  width: 100%;

  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.textarea {
  resize: none;
  width: 100%;
  min-height: 55px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  padding: 8px 12px;
  outline: none;

  font-size: 14px;
  color: var(--text-color);
  @include text('regular');

  &::placeholder {
    color: $text-placeholder;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
  }
}

.textareaControl {
  border: none;
  padding: $padding10;
  background-color: transparent;
  flex-grow: 1;

  font-size: 18px;
  color: inherit;
  @include text('regular');

  outline: none;
  resize: none;

  &::placeholder {
    color: $text-placeholder;
  }

  &:disabled {
    background-color: #e8e8e8 !important;
  }
}


.errorMessage {
  color: var(--main-color);
  font-size: 14px;
  @include text('regular');

  position: absolute;
  bottom: -20px;
  left: 7px;
}
