@import "src/utils/styles";
@import "src/utils/mixin";

.mainContainer {
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: 20px 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  height: var(--app-height);
  @include bg-gradient('main');
}

.mainContainerOverflowHidden {
  max-width: 1024px;
  width: 100%;
  margin: auto;
  padding: 20px 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  height: var(--app-height);
  @include bg-gradient('main');
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $padding20;
}

.qCard {
  width: calc(100% / 2 - 10px);
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: calc(100% / 3 - 10px);
  }
}

.update {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #FFFFFF;

  &Title {
    color: var(--text-color);
    font-size: 18px;
    @include text('semibold');
  }

  &Text {
    color: var(--text-color);
    font-size: 16px;
    line-height: 18px;
    @include text('regular');
  }

  &Button {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
}
