@import "src/utils/styles";
@import "src/utils/mixin";

.cardList {
  padding: $padding20 $padding20 80px;
}

.card {
  padding: $padding10;
  border: 1px solid $border-color;
  border-radius: $border-radius-12;
  background-color: #FFFFFF;
  margin-bottom: $padding20;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
}

.description {
  padding: 2px 0 5px;

  &Item {

  }

  &Separator {
    margin: 0 5px;
    color: #6E7591;
    font-size: 10px;
    line-height: 10px;
    @include text('light');
  }

  &Icon {
    width: 12px;
    text-align: center;
    color: #6E7591;
    font-size: 12px;
    margin-right: 6px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.iClass {
  color: #6E7591;

  &Mr10 {
    margin-right: 10px;
  }

  &Ml10 {
    margin-left: 10px;
  }

  &Mr5 {
    margin-right: 5px;
  }

  &Ml5 {
    margin-left: 5px;
  }

}

.horizontal {
  &P10 {
    padding: 10px 0;
  }

  &PTop10 {
    padding-top: 10px;
  }

  &PBottom10 {
    padding-bottom: 10px;
  }
}

.secondText {
  color: #6E7591;
  font-size: 10px;
  line-height: 10px;
  @include text('light');

  &Overflow {
    max-width: 50px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
  }
}

.title {
  color: var(--text-color);
  font-size: 16px;
  line-height: 20px;
  @include text('medium');

  margin: 5px 0 0;
}

.text {
  color: #6E7591;
  font-size: 12px;
  //line-height: 12px;
  @include text('medium');

  margin: 8px 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.footer {
  display: flex;
  align-items: center;
}

.object {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.status {
  padding: 5px 10px;
  border-radius: 40px;
  background-color: #F0EDFD;

  &Text {
    color: #613BE7;
    font-size: 12px;
    @include text('semibold');
  }
}


.search {
  padding: 10px 20px 0;
  display: flex;
  justify-content: space-between;

  &Input {
    width: calc(100% - 100px)
  }

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    &:disabled {
      opacity: 0.5;
    }
  }
}

.input {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;
}

.inputRange {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

  &Disabled {
    background-color: #f6f6f6;
    border: 1px solid $text-placeholder;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.dataPickerWrapper {
  position: relative;
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Tooltip {
    position: absolute;
    top: 5px;
    left: 30px;
    color: var(--main-color);
    font-size: 10px;
    @include text('ligth');
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.filterWrapper {
  padding: 10px 20px 0;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.selectDateLabel {
  color: var(--text-color);
  font-size: 12px;
  @include text('regular');
  margin-top: 0;
  margin-bottom: 1px;
}

.separator {
  padding: 5px 0;
}
