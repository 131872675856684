@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: 20px 20px 0;
}

.scannerControl {
  padding: 20px;
  flex-grow: 1;

  &OutputText {
    color: var(--text-color);
    font-size: 16px;
    @include text('light');
    margin: 20px 0 0;
  }

  &Ul {
    padding: 0;
    margin: 0;
  }

  &Li {
    list-style: none;
    padding: 5px 0;
  }
}

.inputCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;

  &Button {
    margin-top: 20px;
    width: 100%;
    height: 54px;
  }
}

.input {
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
}

.buttonWrap {
  display: flex;
  justify-content: center;
}

.toggle {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 106px;
  height: 136px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid $border-color;
  padding: 2px;

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid $text-placeholder;
    background-color: #FFFFFF;

    &:last-of-type {
      width: 100px;
    }
  }

  &Image {
    width: 100px;
    object-fit: contain;
  }
}

.deviceNotFound {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border-radius: 12px;

  &Text {
    color: var(--text-color);
    font-size: 16px;
    @include text('light');
    margin: 3px 0;
    text-align: center;

    &:first-of-type {
      margin-top: 80px;
    }
  }

  &Buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
}

.inventoryForm {
  padding: 0 20px 20px;

  &Box {
    padding: 5px 0;


    &:last-of-type {
      padding-top: 20px;
      text-align: center;
    }
  }

  &Row {
    display: flex;
    justify-content: space-between;
  }

  &Scan {
    width: 70%;

    &:last-of-type {
      width: 30%;
      padding-left: 5px;
    }
  }

  &ScanBack {
    padding: 0 20px;
    margin-top: 20px;
  }

  &Label {
    color: var(--text-color);
    font-size: 14px;
    @include text('light');
    display: inline-block;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  &State {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  &Button {
    width: 19%;
    height: 56px;
    padding: 5px;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    outline: none;
    border-radius: 12px;
    color: var(--text-color);
    font-size: 12px;
    @include text('light');

    &:active {
      opacity: 0.7;
    }

    &Active {
      background-color: #C32B46;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
    }
  }
}

.list {
  padding: 20px;
}

.inventoryCard {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px 0;
  margin-bottom: 20px;

  &Head {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
  }

  &HeadText {
    color: var(--text-color);
    font-size: 14px;
    @include text('light');
    margin: 0;
    display: inline-block;
  }

  &Body {
    padding: 0 10px 10px;
  }

  &Name {
    color: var(--text-color);
    font-size: 24px;
    @include text('regular');
    margin: 0;
  }

  &FilesCount {
    color: var(--text-color);
    font-size: 14px;
    @include text('light');
  }

  &Footer {
    display: flex;
    justify-content: space-between;
    padding: 13px 10px 3px;
  }

  &FooterText {
    color: var(--text-color);
    font-size: 14px;
    @include text('semibold');
    line-height: 14px;
    margin: 0;
    display: inline-flex;
    align-items: flex-end;

    & span {
      font-size: 12px;
      @include text('light');
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.errLocationText {
  color: var(--text-color);
  font-size: 14px;
  @include text('semibold');
}

.control {
  display: inline-flex;

  &Button {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 24px;
    padding: 0 10px;
    color: var(--text-color);
  }
}

.scannerWrapper {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 20px auto;
}

.scanner {
  width: 100%;
  height: 100%;
}


