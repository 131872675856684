@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: 100px $padding20 0;
}

.logo{
  width: 206px;
  height: 206px;
  margin: 0 auto;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title{
  font-size: 20px;
  color: #ffffff;
  @include text('regular');

  text-align: center;
}

.space30 {
  height: $space30;
}

.form{
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
}

.loaderText{
  color: #ffffff;
  font-size: 16px;
  @include text('regular');

  text-align: center;
}

.policy{
  font-size: 15px;
  line-height: 16px;
  color: #FFFFFF;
  @include text('medium');
}
