@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20;
}

.title {
  font-size: 16px;
  color: var(--text-color);
  @include text('regular');

  margin: 0 0 5px;
}

.text {
  font-size: 12px;
  color: $text-placeholder;
  @include text('regular');

  margin: 0;
}


.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: $padding20;
}

.search {
  width: 70%;
}

.button {
  width: 25%;
}

.loadingText {
  color: #ffffff;
  font-size: 14px;
  @include text('regular');
}

.listContainer {
  padding-top: 40px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.locationCard {
  @include flexCard()
}
