@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  padding: $padding20 $padding20 0;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--text-color);
  margin: 10px 0 20px 0;

  &M20 {
    width: 100%;
    height: 1px;
    background-color: var(--text-color);
    margin: 20px 0;
  }

  &NotLine {
    background-color: transparent;
  }
}

.title {
  color: var(--text-color);
  font-size: 18px;
  line-height: 18px;
  @include text('regular');

  margin-top: 5px;
  margin-bottom: 0;

  &Sum {
    font-size: 20px;
    line-height: 20px;
    @include text('regular');
    display: inline-block;
    margin: 0 3px 0 6px;
  }

  &Percent {
    font-size: 14px;
    line-height: 14px;
    @include text('regular');
    display: inline-block;
    margin: 0 3px;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $padding20;

  &Box {
    padding: 0 20px;
  }

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding10 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 40px;
    line-height: 40px;
    @include text('regular');

  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin-top: $padding10;

    &:last-of-type {
      margin: 0;
    }
  }
}

.card {
  width: 48%;
  min-height: 80px;
  background-color: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  padding: 15px 0 5px 0;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  &Simple {
    padding: 5px;
  }

  &Subtext {
    color: var(--text-color);
    font-size: 14px;
    line-height: 18px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 300;
    margin: 3px 0 0 0;
    text-align: center;
  }

  &Text {
    color: var(--text-color);
    font-size: 22px;
    line-height: 24px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: center;

    @media (max-width: 395px) {
      font-size: 20px;
      line-height: 20px;
    }

    @media (max-width: 340px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  &Percent {
    color: var(--text-color);
    font-size: 12px;
    line-height: 14px;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: 300;
    position: absolute;
    top: 3px;
    right: 10px;
  }
}

.dataPickerWrapper {
  position: relative;
  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Tooltip {
    position: absolute;
    top: 5px;
    left: 30px;
    color: var(--main-color);
    font-size: 10px;
    @include text('ligth');
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.inputRange {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.filterWrapper {
  padding: 10px 20px 0;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
  z-index: 2;
  position: relative;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');

    &:nth-child(1) {
      margin-right: 3px;
    }

    &:nth-child(2) {
      margin-left: 3px;
    }
  }
}

.inputWrap {
  border: 1px solid #DCE1EF;
  border-radius: $border-radius-12;
  background-color: #ffffff;
  overflow: hidden;
  margin: 10px 0;
}

.select {
  display: flex;
  width: 100%;
  //height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;

  &Disabled {
    background-color: #e8e8e8;
    border: 1px solid $text-placeholder;
    color: var(--text-color) !important;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  &Header {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &Footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 100;
  }
}

.controls {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 100;
}

.button {
  position: fixed;
  right: 20px;
  min-width: 50px;
  max-width: 130px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-color);
  margin-bottom: 10px;
  z-index: 1;
  padding: 0 5px;

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    display: inline-block;
    margin-left: 5px;
    flex-grow: 1;
  }
}

.cart {
  top: 80px;
  height: 55px;
  z-index: 2;

  @media (max-width: 395px) {
    height: 45px;
  }
}

.zoomIn {
  top: 50%;
}

.zoomOut {
  top: calc(50% + 60px);
}

.home {
  left: 20px;
  bottom: 10%;
}

.back {
  left: 160px;
  bottom: 10%;
  width: 100px;
}

.navigate {
  bottom: 10%;

  & svg {
    transform: rotate(45deg);
  }
}

.balloon {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;
  position: relative;
  width: 300px;

  &Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &Close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    outline: none;

  }

  &Strong {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
    margin: 0;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 16px;
    @include text('regular');
    margin: 0;
  }

  &Separator {
    padding: 5px 0;
  }

  &More {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
    margin-left: 20px;
    padding: 0 10px;
  }
}

.balloonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border: 2px solid var(--main-color);
  border-radius: 12px;
}

.switcher {
  // position: fixed;
  top: 80px;
  left: 20px;
  right: 20px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 1;
  margin-bottom: 10px;

  &Button {
    width: 23%;
    color: var(--main-color);
    font-size: 12px;
    @include text('regular');
    border: 1px solid var(--main-color);
    border-radius: 6px;
    background-color: #ffffff;
    padding: 7px 2px;
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 395px) {
      font-size: 9px;
      padding: 5px 2px;
    }

    &:disabled {
      background-color: #e7e7e7;
      opacity: .5;
    }

    &Active {
      background-color: var(--main-color);
      color: #FFFFFF;
    }
  }
}

.listOrders {
  padding: 20px;

  &Item {
    background-color: #FFFFFF;
    border-radius: 12px;
    border: 1px solid var(--main-color);
    padding: 10px;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;


    &Flex-end {
      align-items: flex-end;
    }
  }

  &ItemFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &Name {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular');
    margin: 0 0 5px;
  }

  &Value {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin: 0 0 5px;
  }

  &Bottom {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin: 0 0 5px auto;
  }

  &Sum {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin: 0;
  }
}

.lineCard {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid var(--main-color);
  padding: 10px;
  margin-bottom: 10px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DCE1EF;
  }

  &Body {
    padding-top: 10px;
  }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Bold {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
    width: 170px;
    display: inline-block;
    text-align: right;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin: 0 0 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-child(4) {
      margin-top: 10px;
    }
  }

  &Subtext {
    display: inline-block;
    margin-left: 10px;
    text-align: right;
  }
}

.table {
  width: 100%;
  border: 1px solid var(--text-placeholder);
  border-radius: 4px;
  margin-top: 20px;
  overflow: hidden;

  &Heads {
    display: flex;
    border-bottom: 1px solid var(--text-placeholder);
    background-color: #FFFFFF;
  }

  &Rows {
    display: flex;
    border-bottom: 1px solid var(--text-placeholder);
    background-color: #FFFFFF;

    &:last-of-type {
      border-bottom: none;
    }

    &:nth-child(even) {
      background-color: #efefef;
    }
  }

  &Cell {
    width: 17%;
    border-left: 1px solid var(--text-placeholder);
    text-align: center;
    padding: 5px;

    &:nth-child(1) {
      width: 49%;
      border-left: none;
      text-align: left;
    }

    &Cross {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 2px;
        background-color: red;
        transform-origin: center;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &Head {
    font-size: 12px;
    color: var(--text-color);
  }

  &Text {
    font-size: 12px;
    color: var(--text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}


.chartContainer {
  height: 250px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 0 20px;
}

.chart {
  height: 250px;
}

.cashCheckTableWrapper {
  width: 100%;
  overflow: hidden;
  padding: 20px;
}

.cashCheckTable {
  width: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.cashCheckTableRowHead {
  display: flex;
}

.cashCheckTableRow {
  display: flex;
  border-bottom: 1px solid var(--disable-color);
  background-color: #ffffff;

  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
}

.cashCheckTableItem {
  padding: 5px;
  border-right: 1px solid var(--disable-color);
  display: flex;

  width: -webkit-fill-available;


  & svg {
    display: inline-block;
    margin-left: auto;
  }

  &:nth-child(even) {
    background-color: rgba(216, 241, 255, 0.25);
  }

  & .cashCheckTableText {
    font-weight: bold;
  }

  &Border {
    border-top: 1px solid #c2c2c2;
  }

  &Type2 {
    width: 10px;
    height: 100%;
    display: inline-block;
  }
}

.cashCheckTableHeadText {
  font-size: 12px;
  font-weight: bold;
  color: var(--text-color);
  text-align: left;
  display: block;
}

.cashCheckTableText {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color);
  text-align: left;
  display: block;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //max-width: 85px;

  &Norm {
    font-weight: 400 !important;
  }
}

.cashCheckTableButton {
  padding: 10px;
  background-color: #b7e7ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cashSearch {
  margin-bottom: 20px;
}


.errorContent {
  margin: 20px;
  padding: 20px;
  border: 1px solid var(--border-color);
  background-color: #ffffff;
  border-radius: 12px;

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    margin: 0;
  }
}

.tabButtonList {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  margin: 20px 0;
}

.tabButton {
  width: 33.3%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color);
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
}


.cashDetailsCard {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  padding: 0;
  overflow: hidden;

  &Row {
    padding: 10px;
    border-bottom: 1px solid var(--disable-color);
    background-color: #ffffff;

    &:nth-child(even) {
      background-color: #f6f6f6;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  &Name {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-placeholder);
    margin: 0;
    display: inline-block;
    width: 100px;
  }

  &Value {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    margin: 0;
  }
}
