@import "src/utils/styles";
@import "src/utils/mixin";

.card {
  width: 100%;
  height: 131px;
  background-color: #ffffff;
  border: 1px solid $disable-color;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: $padding10;
  padding: $padding10;
}

.titleWrap {
  width: 100%;
  height: 20px;
  overflow: hidden;
  background: #eee;
  border-radius: 4px;
  margin-top: 10px;
}

.title {
  width: 100%;
  height: 30px;
  background: linear-gradient(-45deg, #eee 30%, #ddd 50%, #eee 70%);
  animation: loader-animation 1.5s infinite linear;
}

.bodyWrap {
  width: 100%;
  height: 60px;
  overflow: hidden;
  background: #eee;
  border-radius: 4px;
}

.body {
  width: 100%;
  height: 60px;
  background: linear-gradient(-45deg, #eee 30%, #ddd 50%, #eee 70%);
  animation: loader-animation 1.5s infinite linear;
}

@keyframes loader-animation {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}
