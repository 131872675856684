@import "src/utils/styles";
@import "src/utils/mixin";

.modal {
  &Title {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Question {
    color: var(--text-color);
    font-size: 14px;
    @include text('semibold');
    margin-top: auto;
  }
}

.modalButtons {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  &Box {
    width: 48%;
    height: 44px;
  }
}

.screenShot {
  width: 100%;
  // height: 249px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 20px;

  &Image {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid var(--main-color);
  }

  &TextWrap {
    padding: 10px 0;
    text-align: center;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Close {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
