@import "src/utils/styles";
@import "src/utils/mixin";

.box {
  display: flex;
  flex-wrap: wrap;
}

.wrapReaderButton {
  text-align: center;
}

.fileReaderButton {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.minText {
  display: block;
  width: 59px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 10px;
  @include textMulish('regular');
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #FFFFFF;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 0 5px 2px #caced9;
  border: 1px solid #caced9;
}

.close::after {
  content: "×"; /* символ крестика */
  position: absolute;
  top: 0;
  right: 7.5px;
  font-size: 20px;
  color: black; /* цвет крестика */
}

.modalFile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #FFFFFF;
  opacity: 0;
  z-index: 100;
  animation: .5s show .5s forwards;

  &Content {
    background-color: #f0edfd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
}

.showImage {
  width: 100%;
  object-fit: contain;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
