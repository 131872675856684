@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.loaderText {
  color: #ffffff;
  font-size: 14px;
  @include text('semibold')
}
