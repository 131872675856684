@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  display: flex;
  justify-content: space-between;
  border-radius: 14px;
  background-color: #ffffff;
  padding: 5px;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 12px;

  color: #6E7591;
  font-size: 14px;
  @include text('regular');

  // flex-grow: 1;
  padding: 5px 0;

  &Active {
    background-color: var(--main-color);
    color: #ffffff;
  }

  &:disabled {
    background-color: #e8e8e8 !important;
  }
}
