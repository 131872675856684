@import "src/utils/styles";
@import "src/utils/mixin";

.form {
  padding: $padding20 $padding20 0;
}

.space {
  padding: $padding10 0;
}

.bottomWrapper {
  height: 57px;
  width: 100%;
  max-width: 475px;
  margin: $padding20 auto $padding20;
}

.fileList {
  display: flex;
  flex-wrap: wrap;
  margin-top: $padding20;
}

.wrapReaderButton {
  text-align: center;
}

.fileReaderButton {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}


.modalText {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');
  margin: 0;
  text-align: center;
}

.modalButtons {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  &Box {
    width: 48%;
    height: 44px;
  }
}


.minText {
  display: block;
  width: 59px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 10px;
  @include textMulish('regular');
}


.taskLabel {
  color: var(--text-color);
  font-size: 14px;
  @include text('medium');
}


.lastRow {
  display: flex;
  width: 100%;

  &Box {
    width: 50%;

    &:last-of-type {
      margin-left: 5px;
    }
  }
}

.errorMessage {
  color: var(--main-color);
  font-size: 10px;
  @include text('regular');

  position: absolute;
  bottom: -13px;
  left: 7px;
  margin: 0;
}

.statusesWrap {
  background-color: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  height: 41px;
  padding: 6px;
}

.statuses {
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  &List {
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    // overflow-x: scroll;
  }

  &Item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    font-size: 14px;
    color: $text-placeholder;
    @include text('regular');

    &Active {
      background-color: var(--main-color);
      color: #FFFFFF;
      border-radius: 6px;
    }
  }
}

.taskFormRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &LabelBox {
    width: 20%;
  }

  &InputBox {
    position: relative;
    width: 75%;
  }
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

  &Disabled {
    background-color: #f6f6f6;
    border: 1px solid $text-placeholder;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.headerText {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.headerSpan {
  display: inline-block;
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');

  &:last-of-type {
    width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
  }
}

.signature {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #FFFFFF;
  margin-top: 20px;
  padding: 20px 20px;

  &Item {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #EDEDED;

    &:first-of-type {
      padding-top: 0;
    }
  }

  &Avatar {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background-color: #166FF6;
    display: flex;
    align-items: center;
    justify-content: center;

    &Text {
      color: #FFFFFF;
      font-size: 14px;
      @include text('semibold');
    }
  }

  &Body {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    width: 70%;
  }

  &TitleWrap {
    display: flex;
    justify-content: space-between;
  }

  &Date {
    color: #A4A4A4;
    font-size: 10px;
    line-height: 18px;
    @include text('regular');
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    padding-right: 10px;
    @include text('semibold');
  }

  &Vote {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
  }
}

.container {
  display: flex;
  border-radius: 14px;
  background-color: #ffffff;
  padding: 5px;
  border: 1px solid var(--main-color);

  margin-top: 20px;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 12px;

  color: #6E7591;
  font-size: 14px;
  @include text('regular');

  flex-grow: 1;
  padding: 5px 0;

  &Active {
    background-color: var(--main-color);
    color: #ffffff;
  }

  &:disabled {
    background-color: #e8e8e8 !important;
  }
}

.icon {
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: calc(var(--app-height) - 290px);
  overflow: auto;
}

.listMessage {
  display: flex;
  flex-direction: column-reverse;
}

.messagesList {
}

.control {
  display: block;
  position: relative;

  &Attachment {
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    padding: 10px 10px;
    background-color: #FFFFFF;
    border-radius: 16px 16px 0 0;

    & img {
      width: 100%;
      border-radius: 10px 10px 0 0;
    }
  }
}

.inputBlock {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  height: 56px;
  border-radius: 12px;
  overflow: hidden;

  &Input {
    flex-grow: 1;
    border: none;
    padding: 0 10px;
  }

  &Button {
    padding: 13px 10px;
    background-color: #FFFFFF;
    border: none;
    outline: none;

    & img {
      width: 24px;
      height: 24px;
    }
  }
}

.inputText {
  width: 100%;
  background-color: #F7F7FC;
  border: none;
  outline: none;
  padding: 6px;

  color: var(--text-color);
  font-size: 14px;
  @include text('regular');
}

.message {
  &Text {
    margin: 0 0 10px;
    font-size: 14px;
    @include text('regular');
    display: inline-block;

    &From {
      color: var(--text-color);
      margin-right: auto;
    }

    &To {
      color: #FFFFFF;
      margin-left: auto;
    }

    &:empty {
      display: none;
    }
  }
}

.messageItem {
  padding: 10px;
  font-size: 14px;
  @include text('regular');

  margin-bottom: 10px;
  width: calc(246px + 20px);
  // min-height: calc((246px + 40px) / 16 * 9);
  display: inline-flex;
  flex-direction: column;

  &From {
    background-color: #FFFFFF;
    border-radius: 16px 16px 16px 0;
    color: var(--text-color);
  }

  &To {
    border-radius: 16px 16px 0 16px;
    background-color: var(--main-color);
    color: #FFFFFF;
    margin-left: auto;
  }

  &Image {
    width: 246px;
    height: calc(246px / 16 * 9);
    object-fit: contain;
    background-color: #f0edfd;
    border-radius: 12px;
  }

  &Date {
    font-size: 10px;
    @include text('regular');
    display: inline-block;

    &From {
      color: var(--text-color);
      margin-right: auto;
    }

    &To {
      color: #FFFFFF;
      margin-left: auto;
    }
  }

  //& span {
  //  font-size: inherit;
  //  color: inherit;
  //  font-family: inherit;
  //}
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #FFFFFF;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 0 5px 2px #caced9;
  border: 1px solid #caced9;
}

.close::after {
  content: "×"; /* символ крестика */
  position: absolute;
  top: 0;
  right: 7.5px;
  font-size: 20px;
  color: black; /* цвет крестика */
}

.modalFile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #FFFFFF;
  opacity: 0;
  z-index: 100;
  animation: .5s show .5s forwards;

  &Content {
    background-color: #f0edfd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
}

.showImage {
  width: 100%;
  object-fit: contain;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.separator {
  display: flex;
  align-items: center;
  padding: 10px;

  color: #EDEDED;
  font-size: 12px;
  @include text('regular');

  &:after {
    content: '';
    width: auto;
    height: 1px;
    margin-left: 5px;
    background-color: #EDEDED;
    display: flex;
    flex-grow: 1;
  }

  &:before {
    content: '';
    width: auto;
    height: 1px;
    margin-right: 5px;
    background-color: #EDEDED;
    display: flex;
    flex-grow: 1;
  }
}

.userName {
  &From {
    color: var(--text-color);
    font-size: 10px;
    display: inline-block;
    margin-left: 5px;
  }

  &To {
    color: #EDEDED;
    font-size: 10px;
    display: inline-block;
    margin-right: 5px;
  }
}

.collapseWrap {
  margin: 20px 0;
  background-color: #FFFFFF;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  overflow: hidden;
  padding-bottom: 20px;
}

.collapse {
  background-color: #FFFFFF;
  padding: 0 10px;

  &Button {
    display: flex;
    align-items: center;
  }

  &Name {
    flex-grow: 1;
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');

    padding: 0 10px;
  }
}

.animIcon {
  display: inline-block;
  transform: rotate(0deg);

  &Open {
    transform: rotate(180deg);
  }
}

.picker {
  width: 100%;
}

.conditionFile {
  display: flex;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #DCE1EF;

  &Body {
    padding-right: 10px;
    display: inline-grid;
    flex-grow: 1;
  }

  &Box {
    display: flex;
    flex-direction: column;
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: inline-block;
  }

  &Button {
    padding: 20px;
    background-color: #f0edfd;
    border-radius: 8px;
    border: none;
    outline: none;
    display: inline-block;
  }
}

.loadingWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loadingText {
  color: var(--text-color);
  font-size: 18px;
  @include text('semibold');
}
