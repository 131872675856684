@import "src/utils/styles";
@import "src/utils/mixin";

.card {
  padding: $padding10;
  border: 1px solid var(--main-color);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.iconAwesome {
  font-size: 32px;
  margin-top: 10px;
  color: var(--main-color);
}

.text {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');

  margin: 10px 0 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
