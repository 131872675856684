@import "src/utils/styles";
@import "src/utils/mixin";

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $padding20;

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding10 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 40px;
    line-height: 40px;
    @include text('regular');

  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin-top: $padding10;
  }
}

.modal {
  width: 100%;
  height: 189px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Title {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    @include text('regular');
    margin-bottom: 20px;
  }

  &Input {
    height: 43px;
    display: flex;
    width: 100%;
    border: 1px solid var(--main-color);
    border-radius: $border-radius-12;
    background-color: #ffffff;
    color: var(--text-color);
    overflow: hidden;
    font-size: 18px;
    line-height: 18px;
    padding: 5px 10px;
    @include text('light');

    outline: none;

    &::placeholder {
      color: $text-placeholder;
    }
  }

  &Button {
    height: 43px;
    margin-top: 20px;
    display: flex;
  }
}
