@import "src/utils/styles";
@import "src/utils/mixin";

.modalText {
  color: var(--text-color);
  font-size: 14px;
  @include text('regular');
  margin: 0;
  text-align: center;
}

.modalButtons {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  &Box {
    width: 48%;
    height: 44px;
  }
}
