@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20;
}

.title {
  font-size: 16px;
  color: var(--text-color);
  @include text('regular');

  margin: 0 0 5px;
}

.text {
  font-size: 12px;
  color: $text-placeholder;
  @include text('regular');

  margin: 0;
}

.list {
  padding-top: 20px;
}

.employee {
  padding: 10px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-bottom: 20px;

  &Name {
    font-size: 16px;
    color: var(--text-color);
    @include text('semibold');
    display: block;
    margin-bottom: 10px;
  }

  &Jobtitle {
    font-size: 14px;
    color: $text-placeholder;
    @include text('regular');
    display: block;
  }
}
