@import "src/utils/styles";

.modalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

.modal {
  width: 255px;
  min-height: 189px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &Full {
    width: 100%;
    padding: 20px;
  }
}

.content {
  width: 255px;
  min-height: 189px;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid var(--main-color);
  padding: $padding20;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  &Full {
    width: 100%;
    padding: $padding10;
    min-height: auto;
  }
}
