@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  padding: 0 $padding20;
  width: 100%;
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #ffffff;
  padding: 10px 20px 10px 10px;

  font-size: 14px;
  color: var(--text-color);
  @include text('regular');
}

.buttonText {
  display: inline-block;
  margin-left: 10px;
}

.buttonHome {
  width: 54px;
  display: flex;
  align-items: center;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #ffffff;
  padding: 10px 20px 10px 10px;

  font-size: 14px;
  color: var(--text-color);
  @include text('regular');
}
