$padding8: 8px;
$padding10: 10px;
$padding20: 20px;

$border-radius-12: 12px;
$border-color: #C32B46;
$text-color: #0D101C;
$text-placeholder: #6E7591;

$main-color: #C32B46;
$second-color: #f68700;
$disable-color: #e3e3e3;
$green-color: #00ff25;
$blue-color: #0080ff;
$pink-color: #ff00d3;

$space30: 30px
