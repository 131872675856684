
.wrapperView {
    /*opacity: 0;*/
    /*animation: show 0.3s ease-in-out forwards;*/
}

.wrapperHide {
    opacity: 1;
    animation: hide 0.3s ease-in-out forwards;
}

@keyframes show {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes hide {
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

#dynamic {
    text-align: center;
    margin-top: 20px;
}

#dynamic svg {
    background-color: #ffffff;
    border-radius: 8px;
}

.line {
    fill: none;
    stroke-width: 2px;
}

.dot {
    fill: white;
    /*stroke: black;*/
    stroke-width: 2px;
}

.text {
    fill: black;
    font-size: 12px;
    text-anchor: middle;
    dominant-baseline: middle;
}

.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}

.grid path {
    stroke-width: 0;
}
