@mixin bg-gradient($type: 'main') {
  @if ($type == 'main') {
    background: linear-gradient(to bottom, #FFFFFF, var(--main-color));
  }
}

@mixin text($type: 'regular') {
  font-family: Poppins, sans-serif;
  font-style: normal;
  @if ($type == 'light') {
    font-weight: 300;
  }
  @if ($type == 'regular') {
    font-weight: 400;
  }
  @if ($type == 'semibold') {
    font-weight: 600;
  }
  @if ($type == 'medium') {
    font-weight: 500;
  }
}

@mixin textMulish($type: 'regular') {
  font-family: Mulish, sans-serif;
  font-style: normal;

  @if ($type == 'light') {
    font-weight: 300;
  }
  @if ($type == 'regular') {
    font-weight: 400;
  }
  @if ($type == 'semibold') {
    font-weight: 600;
  }
  @if ($type == 'medium') {
    font-weight: 500;
  }
}

@mixin flexCard() {
  width: calc(100% / 2 - 10px);
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: calc(100% / 3 - 10px);
  }
}


@mixin tableColumn() {
  width: 60px;

  &:nth-child(1),
  &:nth-child(2) {
    .tableHeadText {
      width: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:nth-child(1) {
    width: 20px;
  }

  &:nth-child(2) {
    width: 40px;
  }

  &:nth-child(3) {
    min-width: 110px;
    flex-grow: 1;
  }
}
