@import "src/utils/styles";

.scrollControl {
  position: fixed;
  bottom: 50px;
  right: 35px;
  background-color: rgba(227, 227, 227, 0.66);
  width: 50px;
  height: 50px;
  border-radius: 12px;
  border: 1px solid var(--main-color);
  animation: up 1s forwards;

  &Button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    border: none;
    outline: none;

    & span {
      font-size: 24px;
      color: var(--main-color);
    }
  }
}

@keyframes up {
  from {
    bottom: 50px
  }
  to {
    bottom: 110px
  }
}
