@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $padding20;
  width: 100%;
}


.card {
  width: 48%;
  height: 70px;
  padding: $padding10;
  border: 1px solid var(--main-color);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 20px;

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    text-align: center;
  }
}

.cardList {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 80px;
}

.photoReportCard {
  padding: $padding10;
  border: 1px solid $border-color;
  border-radius: $border-radius-12;
  background-color: #FFFFFF;
  margin-bottom: $padding20;

  &Header {
    display: flex;
    justify-content: space-between;
  }

  &TextSm {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
  }

  &Title {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
  }

  &Footer {
    display: flex;
    justify-content: space-between;
  }
}

.form {
  padding: 20px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &Date {
    color: var(--text-color);
    font-size: 14px;
    @include text('semibold');
    margin: 0;

    & span {
      color: var(--text-color);
      font-size: 12px;
      @include text('light');
      display: inline-block;
      margin-right: 5px;
    }
  }

  &ObjName {
    color: var(--text-color);
    font-size: 16px;
    @include text('semibold');
    margin: 0;
    display: flex;
    flex-direction: column;

    & span {
      color: var(--text-color);
      font-size: 14px;
      @include text('light');
      display: inline-block;
      margin-right: 10px;
    }
  }
}

.space {
  padding: $padding10 0;
}

.bottomWrapper {
  height: 57px;
  width: 100%;
  max-width: 475px;
  margin: $padding20 auto $padding20;
}
