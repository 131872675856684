@import "src/utils/styles";
@import "src/utils/mixin";

.cardList {
  padding: $padding20 $padding20 80px;
}

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $padding20;

  &Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding10 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 40px;
    line-height: 40px;
    @include text('regular');

  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin-top: $padding10;
  }
}

.card {
  padding: $padding10;
  border: 1px solid $border-color;
  border-radius: $border-radius-12;
  background-color: #FFFFFF;
  margin-bottom: $padding20;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px;
}

.description {
  padding: 2px 0 5px;

  &Item {
    display: flex;
    align-items: center;
    padding: 2px 0;
  }

  &Separator {
    margin: 0 5px;
    color: #6E7591;
    font-size: 10px;
    line-height: 10px;
    @include text('light');
  }

  &Icon {
    width: 12px;
    text-align: center;
    color: #6E7591;
    font-size: 12px;
    margin-right: 6px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.iClass {
  color: #e3e3e3;

  &Animate {
    opacity: 0;
    animation: ping 1s ease-in-out infinite;
    color: red;
  }

  &Mr10 {
    margin-right: 10px;
  }

  &Ml10 {
    margin-left: 10px;
  }

  &Mr5 {
    margin-right: 5px;
  }

  &Ml5 {
    margin-left: 5px;
  }

}

@keyframes ping {
  0% {
    opacity: 0
  }
  25% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: .5;
  }
  100% {
    opacity: 0
  }
}


.secondText {
  color: #6E7591;
  font-size: 10px;
  line-height: 10px;
  @include text('light');

  &Overflow {
    max-width: 50px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
    text-overflow: ellipsis;
  }
}

.title {
  color: var(--text-color);
  font-size: 16px;
  line-height: 16px;
  @include text('medium');

  margin: 5px 0 0;
}

.text {
  color: #6E7591;
  font-size: 12px;

  @include text('medium');

  margin: 8px 8px 0 0;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;

  &Sum {
    color: var(--text-color);
    font-size: 16px;

    @include text('light');

  }
}

.footer {
  display: flex;
  align-items: center;
}

.object {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.status {
  padding: 5px 10px;
  height: 30px;
  min-width: 30px;
  border-radius: 40px;
  background-color: #F0EDFD;

  &Text {
    color: #613BE7;
    font-size: 12px;
    @include text('semibold');
  }

  &Mr {
    margin-right: 10px;
  }
}


.search {
  padding: 10px 20px 0;
  display: flex;
  justify-content: space-between;

  &Input {
    width: calc(100% - 100px)
  }

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    &:disabled {
      opacity: 0.5;
    }
  }
}

.input {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;
}

.inputRange {
  display: flex;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

  &Disabled {
    background-color: #f6f6f6;
    border: 1px solid $text-placeholder;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

.dataPickerWrapper {

  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.filterWrapper {
  padding: 10px 20px 0;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.textWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form {
  padding: $padding20 $padding20 0;

  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  &DabbleRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &Col {
    width: 48%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  &Label {
    width: 30%;
    display: inline-block;
  }

  &Input {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 70%;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');

    margin-left: 5px;
    padding: 0 10px;
  }

  &TextareaWrapper {
    padding-top: 20px;
  }

  &Textarea {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    width: 100%;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
    margin-top: 5px;
    padding: 5px;
    resize: none;
  }

  &Option {
    display: flex;
    align-items: center;
  }

  &OptionLabel {
    width: 200px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &OptionView {
    width: 40px;
    height: 20px;
  }
}

.bottomWrapper {
  height: 57px;
  width: 100%;
  max-width: 475px;
  margin: $padding20 auto $padding20;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.color {
  width: 100px;
  height: 20px;
  border-radius: 8px;
  display: inline-block;
}

.fileList {
  display: flex;
  flex-wrap: wrap;
  margin-top: $padding20;
}

.uploadWrapper {
  padding: 20px 0;

  &Row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &Col {
    width: 48%;
    position: relative;
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    @include text('lenght');
  }

  &FileName {
    position: absolute;
    bottom: -20px;
    color: var(--text-color);
    font-size: 12px;
    @include text('lenght');

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    height: 20px;
    display: inline-block;
  }

  &Button {
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    min-height: 41px;
    height: 100%;
    border: 1px solid var(--main-color);
    color: var(--text-color);
    font-size: 12px;
    @include text('lenght');
  }
}

.wrapReaderButton {
  text-align: center;
}

.fileReaderButton {
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.minText {
  display: block;
  width: 59px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
  font-size: 10px;
  @include textMulish('regular');
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.selectDate {
  & input {
    text-align: center;
  }
}

.tableWrap {
  padding: 10px;
  background-color: #ffffff;
  overflow-x: scroll;
  margin: 20px 0;
  border-radius: 8px;
}

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;

  &Row {
    display: flex;
    border-bottom: 1px solid $border-color;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &Cel {
    display: inline-flex;
    justify-content: center;
    width: 100px;
    border-left: 1px solid $border-color;
    padding: 5px;

    &:first-of-type {
      width: 200px;
      justify-content: start;
      border-left: none;
    }
  }
}

.buttonPayment {
  width: 100%;
  background-color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 8px;

  @include text('regular');
  color: var(--text-color);
  font-size: 16px;
  padding: 5px 10px;
}

.progressBar {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  position: relative;
  background-color: #f0edfd;
  margin-top: 5px;

  &Line {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 3px;
    border-radius: 2px;
    background-color: blue;
  }
}

.companyBox {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &Row {
    display: flex;
    margin-bottom: 10px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &Button {
    padding: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: var(--main-color);
    @include text('regular');
  }
}

.signature {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  background-color: #FFFFFF;
  margin-top: 20px;
  padding: 20px 20px;

  &Item {
    padding: 20px 0;
    display: flex;
    border-bottom: 1px solid #EDEDED;

    &:first-of-type {
      padding-top: 0;
    }
  }

  &Avatar {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    background-color: #166FF6;
    display: flex;
    align-items: center;
    justify-content: center;

    &Text {
      color: #FFFFFF;
      font-size: 14px;
      @include text('semibold');
    }
  }

  &Body {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    width: 70%;
  }

  &TitleWrap {
    display: flex;
    justify-content: space-between;
  }

  &Date {
    color: #A4A4A4;
    font-size: 10px;
    line-height: 18px;
    @include text('regular');
  }

  &Title {
    color: var(--text-color);
    font-size: 14px;
    padding-right: 10px;
    @include text('semibold');
  }

  &Vote {
    color: var(--text-color);
    font-size: 12px;
    @include text('regular');
  }
}

.container {
  display: flex;
  border-radius: 14px;
  background-color: #ffffff;
  padding: 5px;
  border: 1px solid var(--main-color);

  margin-top: 20px;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 12px;

  color: #6E7591;
  font-size: 14px;
  @include text('regular');

  flex-grow: 1;
  padding: 5px 0;

  &Active {
    background-color: var(--main-color);
    color: #ffffff;
  }

  &:disabled {
    background-color: #e8e8e8 !important;
  }
}

.icon {
  width: 48px;
  height: 48px;

  & img {
    width: 100%;
  }
}

.selectedColor {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.close {
  position: absolute;
  right: 8px;
  top: 8px;
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: #FFFFFF;
  z-index: 2;
  border-radius: 50%;
  box-shadow: 0 0 5px 2px #caced9;
  border: 1px solid #caced9;
}

.close::after {
  content: "×"; /* символ крестика */
  position: absolute;
  top: 0;
  right: 7.5px;
  font-size: 20px;
  color: black; /* цвет крестика */
}

.modalFile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #FFFFFF;
  opacity: 0;
  z-index: 100;
  animation: .5s show .5s forwards;

  &Content {
    background-color: #f0edfd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
}

.showImage {
  width: 100%;
  object-fit: contain;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal {
  width: 100%;
  height: 189px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &Title {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    @include text('regular');
    margin-bottom: 20px;
  }

  &Input {
    height: 43px;
    display: flex;
    width: 100%;
    border: 1px solid var(--main-color);
    border-radius: $border-radius-12;
    background-color: #ffffff;
    color: var(--text-color);
    overflow: hidden;
    font-size: 18px;
    line-height: 18px;
    padding: 5px 10px;
    @include text('light');

    outline: none;

    &::placeholder {
      color: $text-placeholder;
    }
  }

  &Button {
    height: 43px;
    margin-top: 20px;
    display: flex;
  }
}
