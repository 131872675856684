@import "src/utils/styles";
@import "src/utils/mixin";

.filter {
  padding: 20px;

  &Text {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular');

    margin-top: 0;
  }

  &Select {
    border: 1px solid var(--border-color);
    height: 40px;
    border-radius: 12px;
    background-color: #ffffff;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
  }

  &Input {
    border: 1px solid var(--border-color);
    height: 40px;
    border-radius: 12px;
    background-color: #ffffff;
    width: 100%;
    padding: 0 10px;
    margin-top: 20px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    outline: none;

    &::placeholder {
      color: var(--text-placeholder);
    }
  }

  &ButtonWrap {
    margin-top: 20px;
    width: 100%;
  }
}

.wrapper {
  padding: 20px;
}

.client {
  padding: 3px;
  background-color: #ffffff;
  border-radius: 12px;

  &Row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;

    &First {
      padding: 10px;
    }
  }

  &Body {
    background-color: #f3f3f3;
    padding: 10px;
    border-bottom-right-radius: 11px;
    border-bottom-left-radius: 11px;
  }

  &Name {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color);
    margin: 0;
  }

  &Subtext {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light-color);
  }

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
  }
}

.history {
  padding: 20px;

  &List {
    padding: 0 20px;
  }
}

.historyCollapse {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;

  &Toggle {
    display: flex;
    flex-direction: column;
    padding: 3px;
  }

  &ToggleBody {
    background-color: #ffffff;
    padding: 5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    &IsExpanded {
      padding: 5px 5px 0;
      border-radius: 0;
    }
  }


  &ToggleText {
    font-size: 14px;
    color: var(--text-color);
    @include text('regular');
  }

  &Body {
    background-color: #f3f3f3;
    padding: 0 5px 5px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &BodyItem {
    border-bottom: 3px solid #ffffff;
  }

  &Row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7px 5px;

    &First {
      padding: 7px 10px;
      align-items: center;
    }
  }

  &Subtext {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light-color);
    @include text('regular');
  }

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    text-align: right;
    @include text('regular');
  }
}

.buttonMore {
  margin-top: 20px;
  width: 100%;
}

.inputRange {
  display: flex;
  width: 100%;
  height: 41px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.separator {
  width: 100%;
  padding: 10px 0;
}

.card {
  border-radius: 12px;
  background-color: #FFFFFF;
  border: 1px solid var(--border-color);
  margin-bottom: 10px;
  overflow: hidden;

  &Head {
    padding: 10px 10px 5px;
  }

  &Body {
    background-color: #f3f3f3;
    padding: 5px 10px 10px;
  }

  &Row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 0;
  }

  &Subtext {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light-color);
    @include text('regular');
  }

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    text-align: right;
    @include text('regular');
  }
}
