@import "src/utils/styles";
@import "src/utils/mixin";

.box {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 57px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;

}

.label {
}

.input {
  height: 53px;
  width: 100px;
  border: none;
  padding: $padding10;
  background-color: transparent;
  font-size: 18px;
  color: var(--text-color);
  @include text('regular');
  text-align: center;

  outline: none;

  &::placeholder {
    color: $text-placeholder;
  }
}

.percentIcon {
  color: var(--text-color);
  font-size: 18px;
  @include text('regular');
}
