@import "src/utils/styles";
@import "src/utils/mixin";

.dataPickerWrapper {

  padding: 20px 20px 0;
  display: flex;
  justify-content: space-between;

  &Data {
    width: calc(100% - 100px);
  }

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }

  &Count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--main-color);
    color: #f0edfd;
    font-size: 12px;
    line-height: 16px;
    @include text('semibold');

  }
}

.filterWrapper {
  padding: 10px 20px 0;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 10px;
}

.search {
  padding: 10px 20px 0;
  display: flex;
  justify-content: space-between;

  &Input {
    width: calc(100% - 100px)
  }

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.input {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.payer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  &LabelBox {
    width: 20%;
  }

  &InputBox {
    position: relative;
    width: 70%;
  }
}
