@import "src/utils/styles";

.wrapper {
  padding: 0 $padding20 30px;
  display: flex;
  position: fixed;
  max-width: 1024px;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

.footer {
  background-color: #ffffff;
  border: 1px solid var(--main-color);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 68px;
  padding: 4px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 5.2);

  border-radius: 10px;
  background: transparent;
  border: 1px solid transparent;

  &:first-of-type {
    background-color: #F0F2F8;
  }

  &:last-of-type {
    background-color: #F0F2F8;
  }

  &Active {
    border: 1px solid var(--main-color);
  }

  &Disabled {
    // background: transparent;
  }
}

.separator {
  width: 4px;
  height: 100%;
  background: transparent;
}
