.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 20px 0;

  &Item {
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 5px);
  }

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
  }
}

.video {
  padding: 20px;
  position: relative;

  & video {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--border-color);
  }

  & canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    z-index: 2;
  }

  &Text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
  }
}

.controls {
  display: flex;
  flex-direction: column;

  &Button {
    background-color: transparent;
    border: none;
    width: 40px;
    height: 40px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &Road {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &Range {
    display: flex;
    flex-grow: 1;
  }

  &Time {
    width: 40px;
    font-size: 12px;
    font-weight: 400;
    color: var(--text-color);
  }
}
