@import "src/utils/styles";
@import "src/utils/mixin";

.container {
  padding: $padding20 $padding20 100px;
}

.box {
  display: flex;
  align-items: center;
  //width: 100%;
  margin: 20px 0;

  &Label {
    width: 20%;
  }

  &Input {
    width: 80%;
    flex-grow: 1;
    padding-left: $padding10;

    &Address {
      padding: 0;
      width: 100%;
    }
  }
}

.label {
  color: #ffffff;
  font-size: 16px;
  @include text('regular')
}

.mapWrapper {
  border-radius: 12px;
  overflow: hidden;
  background-color: blue;
}
