@import "src/utils/styles";
@import "src/utils/mixin";

.taskFormRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &LabelBox {
    width: 20%;
  }

  &InputBox {
    position: relative;
    width: 70%;
  }
}

.taskLabel {
  color: var(--text-color);
}

.select {
  display: flex;
  width: 100%;
  height: 30px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  overflow: hidden;
  padding: 0 8px;

  &Disabled {
    background-color: #e8e8e8;
    border: 1px solid $text-placeholder;
    color: var(--text-color) !important;
  }

  &Error {
    background-color: rgba(195, 43, 70, 0.39);
    color: #ffffff;
  }
}

