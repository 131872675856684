@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  background-color: #FFFFFF;
  border-radius: 12px;
  width: 100%;
  min-height: 41px;
  height: 100%;

  display: flex;
}

.button {
  position: relative;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: var(--text-color);
  border: 1px solid var(--main-color);
  border-radius: 8px;

  &Bold {
    @include text('semibold');
  }

  &Regular {
    @include text('regular');
  }

  &:disabled {
    background-color: $disable-color;
    border-radius: inherit;
    opacity: 0.6;
    pointer-events: none;
  }

  &:active {
    opacity: 0.7;
  }
}
