@import "src/utils/styles";
@import "src/utils/mixin";

.content {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;

  & p {
    color: var(--text-color);
    font-size: 16px;
    @include text('regular')
  }
}
