@import "src/utils/styles";
@import "src/utils/mixin";

.list {
  padding: 20px;

  &Item {
    display: flex;
    width: 100%;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid var(--main-color);
    padding: 10px;
    margin-bottom: 10px;
  }

  &Body {
    width: 80%;
  }

  &Info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &Date {
    display: inline-block;
    color: $text-placeholder;
    font-size: 12px;
    @include text('medium');
  }

  &Title {
    color: var(--text-color);
    font-size: 12px;
    @include text('medium');
    margin: 0;
  }
}

.filterWrapper {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  &Button {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 90px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}

.inputRange {
  display: flex;
  max-width: 280px;
  width: 100%;
  height: 33px;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);
  margin-right: 10px;

  & input {
    text-align: center;
  }
}

.filterWrapper {
  padding: 10px 20px 0;
}

.filter {
  padding: 20px;
}

.filterCollapse {
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #DCE1EF;
  padding: 5px 10px 10px;
  width: 100%;
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &Button {
    background-color: #FFFFFF;
    border: 1px solid var(--main-color);
    border-radius: 12px;
    height: 33px;
    width: 170px;
    color: var(--text-color);
    font-size: 14px;
    @include text('regular');
  }
}
