@import "src/utils/styles";
@import "src/utils/mixin";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.text {
  color: inherit; //var(--text-color);
  font-size: 14px;
  line-height: 14px;
  @include text('regular');

  display: inline-block;
  margin-right: 5px;
}

.number {
  color: inherit; // var(--text-color);
  font-size: 40px;
  line-height: 40px;
  @include text('regular');
}

.inputRange {
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: $border-radius-12;
  background-color: #ffffff;
  color: var(--text-color);

  margin-top: 20px;

  & input {
    text-align: center;
  }
}

.send {
  height: 57px;
}

.list {
  padding: 20px;
}

.card {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;

  &Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5pt solid #c2c2c2;
  }

  &Body {
    padding: 10px 0;
  }

  &Title {
    color: var(--text-color);
    font-size: 16px;
    line-height: 16px;
    @include text('semibold');
  }

  &Type {
    color: var(--text-color);
    font-size: 14px;
    line-height: 14px;
    @include text('light');
  }

  &Text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 14px;
    @include text('regular');

    display: block;
    margin: 10px 0;
  }

  &Subtext {
    color: var(--text-color);
    font-size: 14px;
    line-height: 14px;
    @include text('light');

    display: inline-block;
    margin-right: 5px;
  }

  &Footer {
    padding-top: 10px;
    border-top: 0.5pt solid #c2c2c2;
  }
}

.listen {
  &Text {
    color: var(--text-color);
    font-size: 14px;
    line-height: 14px;
    @include text('light');

    display: inline-block;
    padding: 2px 10px;
    border: 1px solid var(--main-color);
    border-radius: 4px 4px 4px 8px;
  }

  &Empty {
    color: var(--text-color);
    font-size: 10px;
    line-height: 10px;
    @include text('light');

    display: inline-block;
    padding: 2px 5px;
  }
}

.search {
  padding: 20px 20px 0;
}

.player {
  position: relative;
  margin: auto;
  width: 100%;

  &Close {
    position: absolute;
    top: -5px;
    right: -5px;

    padding: 10px;
    z-index: 100;

    & svg {
      width: 20px;
      height: 20px;
      fill: var(--main-color);
      background-color: #FFFFFF;
    }
  }
}

.keyboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &Key {
    width: 30%;
    height: 60px;
    border: 1px solid var(--main-color);
  }
}
