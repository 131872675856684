@import "src/utils/styles";
@import "src/utils/mixin";

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  &Item {
    width: 40%;
  }
}

.scanner {
  &Wrapper {
    position: relative;
    width: 215px;
    height: 215px;
    margin-bottom: 20px;
  }

  &Button {
    height: 43px;
  }
}

.wrapper {
  padding: 20px;
}

.object {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #FFFFFF;
  border-radius: 8px;

  &Name {
    font-size: 16px;
    color: var(--text-color);
    @include text('regular');

    margin: 0 0 5px;
  }

  &TableWrap {
    width: 100%;
    margin-right: 20px;
    overflow-x: scroll;
  }
}

// ========================================================

.table {
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  background-color: #FFFFFF;
  overflow: scroll;
  width: 100%;


  &Header {
    display: flex;
    border-bottom: 1px solid #7a7a7a;
    padding: 3px 0;
  }

  &Head {
    padding: 0 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

    width: 70px;

    &:nth-child(1) {
      width: 180px;
    }
  }

  &HeadText {
    font-size: 14px;
    text-align: center;
    @include text('regular');

    &:empty {
      display: none;
    }
  }

  &HeadInner {
    display: flex;
    height: 100%;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;
    background-color: #f3f3f3;
    padding: 3px 0;
    border-radius: 2px;
  }

  &Row {
    display: flex;

    &:nth-child(2n) {
      background-color: #efefef;
    }

    &Name {
      width: 160px;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &Text {
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &:empty {
        display: none;
      }
    }
  }

  &RowBtn {
    font-size: 12px;
    @include text('regular');
    padding: 7px 6px;
    background-color: var(--main-color);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    outline: none;
  }

  &RowItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3px;
    border-right: 1px solid #7a7a7a;

    &:last-of-type {
      border-right: none;
    }

    width: 70px;

    &:nth-child(1) {
      width: 180px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      align-items: center;
      justify-content: center;
    }
  }
}

.inputRange {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;

  & input {
    text-align: center;
  }
}
