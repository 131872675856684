@import "src/utils/styles";
@import "src/utils/mixin";

.header {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: $padding20;
}

.name {
  color: #6E7591;
  font-size: 12px;
  @include text('regular');

  margin: 0;
}

.appName {
  color: var(--text-color);
  font-size: 16px;
  @include text('regular');

  margin: 0;
}

.notification {
  width: 68px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}
